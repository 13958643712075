<template>
  <div v-if="readyToRender" class="payment-page-client-guest">
    <div class="flex ds-wrapper">
      <p class="text-primary ml-auto mr-2 mb-2">{{ paymentRequestDetail.paymentRequestId }}</p>
    </div>
    <RequestHeader :payment-request="paymentRequestDetail" />
    <div class="ds-wrapper">
      <template v-if="idMatrix.verifyCount >= 4 && !processPayment">
        <inactive-link :message="'Unfortunately we\'re unable to offer you a payment plan as we can\'t verify your identity.'"/>
      </template>
      <template v-if="!onlyPayNow && !showSuccessBox && processPayment">
        <div class="border border-dashed border-neutral-400 mt-4 mb-10"></div>
        <h2 v-if="!showSelectedOption" class="text-primary text-center mb-10 regular-caps">Select payment option</h2>
        <template v-if="!showSelectedOption">
          <payment-option :products="products" @selectProduct="selectProduct" :transactions="transactions" />
          <template v-if="showPayNow && displayPayNow">
            <div class="flex justify-center my-14" v-if="idMatrix.verifyCount >= 4 && processPayment">
              <vs-alert color="warning" icon="error_outline">
                <p>Unfortunately we can't offer you a payment plan as we're unable to verify your identity.  You can proceed with any payment option available below.</p>
              </vs-alert>
            </div>

            <pay-now-options :amount="paymentRequestDetail.amount.$numberDecimal" :products="products" :card-options="payNowCards" @selectProduct="selectProduct" :eft-enabled="eftEnabled" />
          </template>
        </template>
        <template v-else>
          <selectPaymentOption
            :amount="paymentRequestDetail.amount.$numberDecimal || paymentRequestDetail.amount"
            @changeSelectedOption="changeSelectedOption"
            :request-option="selectedRequestOption"
            :selected-plan="selectedPlan"
            :transactions="prTransactionSchedule"
          />
        </template>
      </template>
      <template v-if="!showSuccessBox">
        <div v-if="contactDetailStepCompleted" class="bg-container-40 py-3 mt-4 px-4 md:px-6 rounded-2xl md-rounded-3xl">
          <div class="flex justify-between items-center">
             <div class="flex gap-4 items-center">
              <img :src="checkMarkIcon" alt="check mark icon" class="w-4 h-3" />
            <h3>Your contact details</h3>
            </div>
            <vs-icon type="flat" @click="contactDetailToggle = !contactDetailToggle " class="text-body-dark material-icons-outlined cursor-pointer" :icon="contactDetailToggle ? 'expand_less' : 'expand_more'" icon-size="large" />
          </div>
          <template v-if="contactDetailToggle">
            <div class="mt-2">
              <p class="mb-1">{{ formData.email }}</p>
              <div class="flex gap-x-4 items-center">
                <p>{{ formData.mobile }}</p>
                <vs-chip color="success" size="small" class="mr-2">Verified</vs-chip>
              </div>
            </div>
            <div class="flex mt-2">
              <vs-button type="border" size="small" class="ml-auto" @click="editDetail('contactDetail')">Edit</vs-button>
            </div>
          </template>
        </div>

        <div v-if="requiredInformationStepCompleted" class="bg-container-40 py-3 mt-4 px-4 md:px-6 rounded-2xl md-rounded-3xl">
          <div class="flex justify-between items-center">
            <div class="flex gap-4 items-center">
              <img :src="checkMarkIcon" alt="check mark icon" class="w-4 h-3" />
              <h3>Required information</h3>
            </div>
            <vs-icon type="flat" @click="requiredInformationToggle = !requiredInformationToggle" class="text-body-dark material-icons-outlined cursor-pointer" :icon="requiredInformationToggle ? 'expand_less' : 'expand_more'" icon-size="large" />
          </div>

          <template v-if="requiredInformationToggle">
            <div class="grid gap-y-8 md:grid-cols-2 md:gap-x-20 mt-5" v-if="inputArray.length > 0">
              <template v-for="(customField, key) in inputArrayFiltered">
                <div
                  :key="key + 0.5"
                  v-if="
                    customField.type !== 'address' &&
                    customField.type !== 'file' &&
                    (customField.editableByCustomer  || customField.requiredToSubmit || (customField.displayToCustomer && Object.keys(customField.value.toString()).length > 0))">
                  <label class="text-sm font-normal text-gray-dark">
                    {{ customField.label }}
                    <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
                    <span v-if="customField.description">
                      <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                        <img class="icon-control" :src="tooltip" alt="tooltip" />
                      </vs-tooltip>
                    </span>
                  </label>
                  <p class="mt-2" v-if="customField.type !== 'address'">
                    <S3FileGenerator v-if="customField.type === 'file'" :document="customField.value" :key="customField.value" />
                    <span v-else>
                      <p class="text-body-dark font-light" v-if="customField.type === 'currency'">$ {{ customField.value }}</p>
                      <p class="text-body-dark font-light" v-else-if="customField.type === 'number'">{{ customField.value }}</p>
                      <p class="text-body-dark font-light" v-else-if="customField.type === 'date'">{{ customField.value ? formattedDate(customField.value) : customField.value }}</p>
                      <p class="text-body-dark font-light" v-else>{{ customField.value }}</p>
                    </span>
                  </p>
                  <p v-if="customField.type === 'address'" class="text-body-dark font-light mt-2" v-html="formatAddress(customField.value)"></p>
                </div>

                <div
                  :key="key + 0.5"
                  v-if="customField.type == 'file' && (customField.editableByCustomer || customField.requiredToSubmit || (customField.displayToCustomer && Object.keys(customField.value).length > 0))">
                  <label class="text-sm font-normal text-gray-dark">
                    {{ customField.label }}
                    <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
                    <span v-if="customField.description">
                      <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                        <img class="icon-control" :src="tooltip"  alt="tooltip" />
                      </vs-tooltip>
                    </span>
                  </label>
                  <div class="addFile" v-if="showPreview(key)">
                    <S3FileGenerator class="text-sm" v-if="customField.value" :document="customField.value" :key="customField.value"/>
                    <a class="" v-if="customField.selectedFile">{{ customField.selectedFile.name }}</a>
                  </div>
                </div>

                <div :key="key + 0.5" v-if="customField.type === 'address' && (customField.editableByCustomer || customField.requiredToSubmit)">
                  <label class="text-sm font-normal text-gray-dark">
                    {{ customField.label }}
                  </label>
                  <p class="text-body-dark font-light mt-2">{{ productAddressFieldArray[customField._id].rawAddress }}</p>
                </div>
              </template>
            </div>
            <div class="flex mt-8">
              <vs-button type="border" size="small" class="ml-auto" @click="editDetail('requiredInformation')">Edit</vs-button>
            </div>
         </template>
        </div>

        <div v-if="yourIdentityStepCompleted" class="bg-container-40 py-3 mt-4 px-4 md:px-6 rounded-2xl md-rounded-3xl">
          <div class="flex justify-between items-center">
            <div class="flex gap-4 items-center">
              <img :src="checkMarkIcon" alt="check mark icon" class="w-4 h-3" />
              <h3>Your identity</h3>
            </div>
            <vs-icon type="flat" @click="identityToggle = !identityToggle" class="text-body-dark material-icons-outlined cursor-pointer" :icon="identityToggle ? 'expand_less' : 'expand_more'" icon-size="large" />
          </div>
          <div class="mt-2" v-if="identityToggle">
            <p>{{ idvData.name }}</p>
            <div class="flex">
              <p>{{ idvData.residentialAddress }}</p>
              <p class="ml-auto">
                {{ idvData.drivingLicence ? `Driver licence number ${maskNumber(idvData.drivingLicence.licenceNumber)}` : `Passport number ${maskNumber(idvData.passportNumber)}` }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="disbursementStepCompleted" class="bg-container-40 py-3 mt-4 px-4 md:px-6 rounded-2xl md-rounded-3xl">
          <div class="flex justify-between items-center">
             <div class="flex gap-4 items-center">
              <img :src="checkMarkIcon" alt="check mark icon" class="w-4 h-3" />
            <h3>{{ defaultDisbursement.sectionHeading }}</h3>
            </div>
            <vs-icon type="flat" @click="disbursementDetailToggle = !disbursementDetailToggle " class="text-body-dark material-icons-outlined cursor-pointer" :icon="disbursementDetailToggle ? 'expand_less' : 'expand_more'" icon-size="large" />
          </div>
          <template v-if="disbursementDetailToggle">
            <div class="mt-2">
              <p class="mb-1">{{ formData.disbursement.accountName }}</p>
              <div class="flex gap-x-4 items-center">
                <p class="para-def-light">BSB: {{ formData.disbursement.accountBsb }}</p>
                <p class="para-def-light">Account: {{ maskNumber(formData.disbursement.accountNumber) }}</p>
              </div>
            </div>
            <div class="flex mt-2">
              <vs-button type="border" size="small" class="ml-auto" @click="editDetail('disbursementDetail')">Edit</vs-button>
            </div>
          </template>
        </div>
      </template>
      <!-- STEPS FOR PAY LATER -->
      <template v-if="showSelectedOption && showExplainer">
        <div class="ds-wrapper mt-14">
          <h2 class="text-center text-primary uppercase mb-6">{{ selectedPlanForDescription.explainer.header }}</h2>
          <div class="custom-texteditor w-full">
            <p class="text-body-dark mb-10 px-4 md:px-6" v-html="selectedPlanForDescription.explainer.content"></p>
          </div>
          <div class="flex justify-center mt-14">
            <vs-button @click="showExplainer = false" color="primary" size="large" class="w-full max-w-400">{{ selectedPlanForDescription.explainer.buttonText }}</vs-button>
          </div>
        </div>
      </template>
      <template v-if="showSelectedOption && !showExplainer && (selectedRequestOption == 'pay-later') && selectedPlan && !showSuccessBox">
        <div id="stepper-div">
          <v-stepper :steps="steps" v-model="step" class="mt-14"></v-stepper>
        </div>
        <template v-if="step === 1">
          <contactDetails :button-text="hasRequiredInformation ? 'Required information' : 'Your identity'" :request-option="selectedRequestOption" @mobileVerificationCompleted="mobileVerified" :payment-request="paymentRequestDetail" :selected-plan="selectedPlanForDescription" />
        </template>
        <template v-if="step === 2 && hasRequiredInformation">
          <required-information
            :button-text="getRequiredInformationButtonText"
            :default-custom-fields="defaultCustomFields"
            :form-data="formData"
            :input-array="inputArray"
            :input-array-filtered="inputArrayFiltered"
            :payment-request="paymentRequestDetail"
            :product-address-field-array="productAddressFieldArray"
            :request-option="paymentTypeForSummary"
            @requiredInformationComplete="requiredInformationComplete"
            @toggleExpand="toggleExpand"
            @getRawAddress="getRawAddress"
            @clearAddress="clearProductFieldAddress"
            :selected-plan="selectedPlanForDescription"
            :states="states"
            :temp-array="tempArray"
          />
        </template>
        <template v-if="(step === 3 && hasRequiredInformation) || (step === 2 && !hasRequiredInformation)">
          <identity-card
            v-if="paymentRequestDetail.requestOptions.includes('pay-later')"
            :formData="formData"
            :idMatrix="idMatrix"
            :authorized_to_use="authorized_to_use"
            :showIdMatrixVerification="showIdMatrixVerification"
            :page_type="page_type"
            :states="states"
            :addressData="addressData"
            :checkCircle="checkCircle"
            :xCircle="xCircle"
            :index="index"
            :privacy="privacyLegals"
            :showConfirmationForSuccess="showConfirmationForSuccess"
            @verifyId="verifyId"
            @getRawAddress="getRawAddress"
            @clearAddress="clearAddress"
            @idVerifyAuthorized="idVerifyAuthorized"
            @changeAddress="getAddressData"
            :hide-id-error-block="hideIdErrorBlock"
            :id-matrix-validations="idMatrixValidations"
            :selected-plan="selectedPlanForDescription"
            :disable-submit="disableIdentitySubmit"
            :check-disbursement-exist="checkDisbursementExist()"
          />
        </template>
        <template v-if="(step === 4 && hasRequiredInformation) || (step === 3 && !hasRequiredInformation)">
          <Disbursement
            v-if="checkDisbursementExist()"
            :index="index"
            :disbursement="formData.disbursement"
            :paymentRequestObjectId="paymentRequestDetail._id"
            :checkCircle="checkCircle"
            @updateDisbursement="handleDisbursement"
          />
        </template>
        <!-- TODO: NEED TO UPDATE CONDITION AFTER APPLYING DISBURSE TO EXTERNAL -->
        <template v-if="(step === 4 && (!hasRequiredInformation || !checkDisbursementExist())) || (step === 3 && !hasRequiredInformation && !checkDisbursementExist()) || (step === 5 && checkDisbursementExist())">
          <payment-method
            :card_options="card_options"
            :direct-debit-legals="directDebitLegals"
            :eft-details="paymentRequestDetail.eftDetails"
            :fee-amount="feeAmount"
            :form-data="formData"
            :hide-pay-now="hidePayNow"
            :initial-payment="initialPayment"
            :page_type="page_type"
            :pay-later-legals="payLaterLegals"
            :pay-now-legals="payNowLegals"
            :payment-method-description="paymentMethodDescription"
            :payment-methods="paymentMethods"
            :payment-request-id="paymentRequestDetail.paymentRequestId"
            :product-type="productType"
            :purchase-amount="purchaseAmount"
            :request-option="paymentTypeForSummary"
            :selected-plan="selectedPlanForDescription"
            :show-pay-now="showPayNow"
            :show-direct-pay-now-from-api-config="showDirectPayNowFromApiConfig"
            :total="total"
            :till-public-key="till_public_key"
            @paymentSuccess="paymentSuccess"
            :transactions="prTransactionSchedule"
            v-if="enablePaymentMethods"
          />
        </template>
      </template>

      <template v-else-if="!showSuccessBox && selectedRequestOption === 'pay-now'">
        <!-- STEPS FOR PAY NOW -->
        <!-- Required Steps -->
        <template v-if="paymentRequestDetail.customFields && paymentRequestDetail.customFields.length > 0">
          <div id="stepper-div">
            <v-stepper :steps="payNowSteps" v-model="step" class="mt-14"></v-stepper>
          </div>
          <template v-if="step === 1">
            <required-information
              :button-text="'Payment method'"
              :default-custom-fields="defaultCustomFields"
              :form-data="formData"
              :input-array="inputArray"
              :input-array-filtered="inputArrayFiltered"
              :payment-request="paymentRequestDetail"
              :product-address-field-array="productAddressFieldArray"
              :request-option="paymentTypeForSummary"
              @requiredInformationComplete="requiredInformationComplete"
              @toggleExpand="toggleExpand"
              @getRawAddress="getRawAddress"
              @clearAddress="clearProductFieldAddress"
              :selected-plan="selectedPlanForDescription"
              :states="states"
              :temp-array="tempArray"
            />
          </template>
          <template v-if="step === 2">
            <payment-method
              :card_options="card_options"
              :direct-debit-legals="directDebitLegals"
              :eft-details="paymentRequestDetail.eftDetails"
              :fee-amount="feeAmount"
              :form-data="formData"
              :hide-pay-now="hidePayNow"
              :initial-payment="initialPayment"
              :page_type="page_type"
              :pay-later-legals="payLaterLegals"
              :pay-now-legals="payNowLegals"
              :payment-method-description="paymentMethodDescription"
              :payment-methods="paymentMethods"
              :payment-request-id="paymentRequestDetail.paymentRequestId"
              :product-type="productType"
              :purchase-amount="purchaseAmount"
              :request-option="paymentTypeForSummary"
              :show-pay-now="showPayNow"
              :show-direct-pay-now-from-api-config="showDirectPayNowFromApiConfig"
              :total="total"
              :till-public-key="till_public_key"
              @paymentSuccess="paymentSuccess"
              :transactions="prTransactionSchedule"
              v-if="enablePaymentMethods"
            />
          </template>
        </template>
        <!-- NOTE Required Steps -->
        <template v-else>
          <payment-method
            :card_options="card_options"
            :direct-debit-legals="directDebitLegals"
            :eft-details="paymentRequestDetail.eftDetails"
            :fee-amount="feeAmount"
            :form-data="formData"
            :hide-pay-now="hidePayNow"
            :initial-payment="initialPayment"
            :page_type="page_type"
            :pay-later-legals="payLaterLegals"
            :pay-now-legals="payNowLegals"
            :payment-method-description="paymentMethodDescription"
            :payment-methods="paymentMethods"
            :payment-request-id="paymentRequestDetail.paymentRequestId"
            :product-type="productType"
            :purchase-amount="purchaseAmount"
            :request-option="paymentTypeForSummary"
            :show-pay-now="showPayNow"
            :show-direct-pay-now-from-api-config="showDirectPayNowFromApiConfig"
            :total="total"
            :till-public-key="till_public_key"
            @paymentSuccess="paymentSuccess"
            :transactions="prTransactionSchedule"
            v-if="enablePaymentMethods"
          />
        </template>
      </template>

      <!-- SUCCESS MESSAGE -->
      <SuccessMessage v-if="showSuccessBox" :customer-portal="customerPortalUrl" :heading= "successMessageHeading" :message="successMessageText" :payment-request="paymentRequestDetail" :request-option="paymentTypeForSummary" :payment-status="paymentStatus" />

      <!-- SUCCESS URL -->
      <SuccessUrl v-if="showSuccessBox && enableRedirectOnSuccess" :redirectData="redirectData" :timerEnabled="true" :timerCount="60"></SuccessUrl>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { TheMask } from "vue-the-mask";
import { RRule } from "rrule";
import { Validator } from "vee-validate";
import RequestHeader from "./components/requestHeader";
import SuccessUrl from "./components/successUrl.vue";
import FailureUrl from "./components/failureUrl.vue";
import RadioBox from "@/views/components/radiobox";
import CurrencyInput from "@/views/components/Input";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PaymentSummary from "./components/paymentSummary.vue";
import VerifyId from "./verifyId.vue";
import OTP from "./otp.vue";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import S3FileGenerator from "@/views/components/S3FileGenerator";
import { sentryLog } from "../../helper/sentryLog";
import Disbursement from "./components/disbursement.vue";

// New design system start
import CardPayIcon from "../components/design-sys-components/icons/CardPayIcon";
import DirectDebitIcon from "../components/design-sys-components/icons/DirectDebitIcon";
import ToolTipIcon from "../components/design-sys-components/icons/ToolTipIcon";
// Third party import
import { mask } from 'vue-the-mask';
import Popper from 'vue-popperjs';
// Credit Cards
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
// Tooltip images
const amex = require("@/assets/images/cards/amex.png");
const amex1 = require("@/assets/images/cards/amex-1.png");
const general = require("@/assets/images/cards/general.png");
const general1 = require("@/assets/images/cards/general-1.png");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const checkMarkIcon = require("@/assets/images/check-mark.png");
// New design system end

const cardImg = require("@/assets/images/card-pic.png");
const tooltip = require("@/assets/images/tooltip_dark.svg");

// NEW PAGE
import { VStepper } from "vue-stepper-component";
import ContactDetails from "./components/contactDetails";
import IdentityCard from "./components/identityCard";
import InactiveLink from './components/InactiveLink.vue';
import PaymentOption from "./components/paymentOption.vue";
import SelectPaymentOption from "./components/selectPaymentOption";
import PayNowOptions from "./components/payNowOptions";
import RequiredInformation from "./components/requiredInformation";
import PaymentMethod from "./components/PaymentMethod";
import SuccessMessage from "./components/SuccessMessage";

const dictionary = {
  en: {
    attributes: {
      mobile: "mobile phone",
      street_address: "street address",
      unit_number: "unit number",
      street_number: "street number",
      street_name: "street name",
      street_type: "street type",
      exp_month: "expiry month",
      exp_year: "expiry year",
      exp_date: "expiry date",
      first_name: "first name",
      last_name: "last name",
      date_of_birth: "date of birth",
      license_number: "licence number",
      license_expiry_date: "licence expiry date",
      license_state: "licence state",
      license_card_number: "licence card number",
      passport_number: "passport number",
      passport_issue_date: "passport issue date",
      passport_expiry_date: "passport expiry date",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
      expiryDate: () => "Please enter a valid Expiry Number",
    },
  },
};

Validator.extend("expiryDate", {
  getMessage: () => "Date is not valid",
  validate: (value) => {
    const regexValue = /^(0[1-9]|1[0-2])\/\d{2}$/.test(value);
    const date = moment(value, "MM-YY");
    if (date < moment()) {
      return false;
    }
    return regexValue;
  },
});

Validator.localize(dictionary);

export default {
  components: {
    S3FileGenerator,
    S3ImageGenerator,
    RadioBox,
    ContactDetails,
    CurrencyInput,
    DatePicker,
    Disbursement,
    IdentityCard,
    InactiveLink,
    TheMask,
    VueGoogleAutocomplete,
    RequestHeader,
    SuccessUrl,
    FailureUrl,
    PaymentSummary,
    VerifyId,
    OTP,
    Popper,
    CardPayIcon,
    DirectDebitIcon,
    ToolTipIcon,
    // new page
    VStepper,
    PaymentOption,
    SelectPaymentOption,
    RequiredInformation,
    PaymentMethod,
    PayNowOptions,
    SuccessMessage,
  },
  data() {
    return {
      // NEW
      initialStep: null,
      readyToRender: false,
      payNowSteps: 2,
      steps: 4,
      step: 1,
      selectedRequestOption: "",
      showSelectedOption: false,
      showExplainer: false,
      selectedPlanForDescription: "",
      contactDetailStepCompleted: false,
      requiredInformationStepCompleted: false,
      yourIdentityStepCompleted: false,
      disbursementStepCompleted: false,
      onlyPayNow: false,
      contactDetailToggle: false,
      requiredInformationToggle: false,
      identityToggle: false,
      disbursementDetailToggle: false,
      checkMarkIcon: checkMarkIcon,
      successMessageText: "",
      successMessageHeading: "",
      disableIdentitySubmit: false,
      paymentStatus: "",
      payNowCards: "",
      previousSelectedOption: "",

      // OLD
      tooltip:tooltip,
      productAddressFieldArray: {},
      productAddressField: {
        rawAddress: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
      },
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      cardsForPaylater: [],
      paymentResponseData: {},
      disableSendConfirmButton: false,
      showCardInputBox: true,
      showNameIsRequired: false,
      showExpDateIsRequired: false,
      showExpired: false,
      showCardNumberIsRequired: false,
      showCvcIsRequired: false,
      redirectData: {},
      showSuccessBox: false,
      hideCardWhenSuccess: true,
      paymentBind: false,
      paymentTypeForSummary: "",
      cardImg: cardImg,
      isOtpInvalid: false,
      isOtpComplete: false,
      paymentRequestDetail: {},
      page_type: "normal",
      baseUrl: process.env.VUE_APP_API_URL,
      customerPortalUrl: process.env.VUE_APP_CUSTOMER_PORTAL_URL,
      portal: process.env.VUE_APP_NAME,
      checkCircle: require("@/assets/images/icons/check-circle.png"),
      xCircle: require("@/assets/images/icons/x-circle.svg"),
      visa: require("@/assets/images/icons/visa.svg"),
      amex: require("@/assets/images/icons/amex.svg"),
      unionPay: require("@/assets/images/icons/unionpay.svg"),
      mastercard: require("@/assets/images/icons/mastercard.svg"),
      warningIcon: require("@/assets/images/icons/alert-circle.svg"),
      appUrl: process.env.VUE_APP_ROOT_API,
      addressData: "",
      formData: {
        payeeEmail : "",
        payeePhone : "",
        streetNameOnly : "",
        sendConfirmText: "",
        scheduleText: "",
        paymentRequestId: "",
        amount: 0,
        reference: "",
        mobile: "",
        maskedMobileNumber: "",
        email: "",
        name: "",
        street_address: "",
        suburb: "",
        state: "",
        postcode: "",
        exp_month: "",
        exp_year: "",
        exp_date: "",
        card_expiry_date: "",
        transaction_token: "",
        transaction_data: "",
        message: "",
        payment_request_from: {
          name: "",
          phone_number: "",
          email: "",
        },
        attachments: "",
        periodLength: 0,
        payment_type: "now",
        payment_method_type: "card",
        address_search: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
        id_type: "DL",
        first_name: "",
        last_name: "",
        middle_name: "",
        date_of_birth: "",
        licenseDetails: {
          license_number: "",
          expiry_date: "",
          state: "",
          card_number: "",
        },
        passportDetails: {
          passport_number: "",
          issue_date: "",
          expiry_date: "",
        },
        verification_code: "",
        confirm_email: "",
        payment_mode: "now",
        selectedPaymentPlan: {},
        paymentUUID: "",
        customerId: "",
        email_verification_code: "",
        commsPreference: {
          email: false,
          sms: false,
        },
        accountName: "",
        bsb: "",
        accountNumber: "",
        customerSelectedPaymentMethod: "CARD",
        disbursement: {},
        rawAddress: "",
      },
      productType: "",
      total: "",
      feeAmount: "",
      purchaseAmount: "",
      customerDetails: {},
      captchaVerified: false,
      wpwlOptions: { style: "plain" },
      paymentJSObject: undefined,
      todayDate: new Date(),
      companyHeader: "",
      companyDescription: "",
      companyLogo: "",
      card_status: {},
      card_options: [],
      till_public_key: "",
      cardWithSurcharge: true,
      isCardAllowed: true,
      paymentPlans: [],
      paymentRequestFrequency: {},
      paymentSchedules: [],
      prTransactionSchedule: [],
      planTransactions: [],
      selectedPlan: "",
      selectedPlanId: "",
      isRecurring: false,
      totalNumberOfPayments: 0,
      scheduleDescription: "",
      scheduleDescriptionArray: [],
      firstPayment: "",
      customFields: [],
      productCustomFields: [],
      defaultCustomFields: [],
      productCustomFieldsNotViewable: [],
      dropdownArray: [],
      inputArray: [],
      inputArrayFiltered: [],
      tempArray: {},
      monthly_quote_data: {},
      fortnightly_quote_data: {},
      quote_data: [],
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      authorized_to_use: false,
      toc_acceptance: false,
      toc_acceptance_direct_debit: false,
      payNowAcceptance: false,
      privacy_acceptance: false,
      showConfirmationForSuccess: false,
      planDisplayName: "",
      showConfimationForError: false,
      payNowConfirmationError: false,
      payLaterConfirmationError: false,
      showConfirmationForPaylaterSuccess: false,
      showConfirmationForPayLaterError: false,
      showMobileVerification: false,
      showPayNow: true,
      showPaymentPlan: false,
      showPaymentOptions: true,
      till_response_uuid: "",
      disablePayNow: false,
      disablePayLater: false,
      showAcceptAgreement: false,
      disableDeviceVerification: false,
      isOtpDisabled: true,
      appUrl: process.env.VUE_APP_ROOT_API,
      isEmailOtpDisabled: true,
      idMatrix: {
        enquiryId: "",
        verified: null,
        verifyCount: 0,
      },
      failureReason: "",
      showVerifyMobile: false,
      showVerifyOTP: false,
      disableLoginOTP: false,
      paymentRID: "",
      loginUrl: "",
      merchantId: "",
      showIdMatrixVerification: true,
      isCustomerLoggedIn: false,
      showPaymentRadioButton: true,
      till_failure_id: "",
      till_failure_id_now: "",
      till_failure_id_later: "",
      isDeviceVerified: false,
      showDirectPayNowFromApiConfig: true,
      hidePayNow: false,
      showLogin: true,
      index: 0,
      otpLoginCode: "",
      phoneNumber: "",
      customerEmail: "",
      loggedIn: false,
      footerBind: false,
      isEmailVerified: false,
      disableEmailVerification: false,
      isEmailOtpInvalid: false,
      merchantDescriptor: "",
      payNowLegalsDefault: {},
      payNowLegals: {},
      privacyLegals: [],
      termsAndCondition: [],
      payLaterLegals: [],
      directDebitLegals: {},
      activePlan: {},
      additionalOpen: false,
      payNowAdditional: false,
      hideIdErrorBlock: true,
      requestUID: '',
      disableButtonAfterSubmit: false,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      amex: amex,
      amex1: amex1,
      general: general,
      general1: general1,
      // tabIndex: 'CARD',
      paymentMethods: ['CARD'],
      dateSuccess: false,
      dateError: true,
      showBsbValid: true,
      bsbSuccess: false,
      bsbError: true,
      cardType: "default",
      cardValid: true,
      cardValidity: true,
      cardFocused: false,
      securityFocused: false,
      cardSuccess: false,
      cvvSuccess: false,
      cardNameValid: false,
      accountNameValid: false,
      accountNumberValid: false,
      isInvalidCvv: false,
      paymentMethodDescription: "",
      paymentDetails: {},
      paymentRequestId: "",
      idMatrixValidations: [],
      customerLegals: {},
      //For new payment page
      products: [],
      transactions: [],
      processPayment: true,
      idvData: "",
      defaultDisbursement: {},
      visitedProducts: [],
      enableRedirectOnSuccess: false,
      eftEnabled: false,
      enablePaymentMethods: false,
    };
  },
  computed: {
    getRequiredInformationButtonText() {
      let buttonText = "Your identity";

      if (this.yourIdentityStepCompleted) {
        if (this.checkDisbursementExist()) {
          buttonText = this.selectedPlan && this.selectedPlan.disbursement && this.selectedPlan.disbursement.sectionHeading ? this.selectedPlan.disbursement.sectionHeading : "Disbursement account";
        } else {
          buttonText = this.selectedPlan.paymentSectionHeading || "Payment method";
        }
      }

      return buttonText;
    },

    hasRequiredInformation() {
      return this.inputArrayFiltered.length;
    },

    displayPayNow() {
      let payNow = true;

      if (this.products && this.products.length) {
        payNow = this.products.some(item => item.isPayNow) && this.paymentRequestDetail.requestOptions.includes("pay-now");
        this.eftEnabled = this.paymentRequestDetail.eftDetails && Object.keys(this.paymentRequestDetail.eftDetails).length ? true : false;
      }

      return payNow;
    },

    payNowAmount() {
      return `$ ${this.addZeroes(this.formData.amount)} paid today`;
    },

    selectedPaymentType() {
      return this.formData.payment_type;
    },

    handlePayLaterDisable() {
      let validPayment = true;

      if (this.tabIndex == 'CARD') {
        if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has('cardHolder')) {
          validPayment = false;
        }
        validPayment = this.disablePayLater || !this.toc_acceptance || !this.payNowAcceptance || !validPayment || this.showExpired || this.showExpDateIsRequired;
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        if (this.formData.accountName == "" || this.formData.bsb == "" || this.formData.accountNumber == "" || this.errors.has('accountNumber')) {
          validPayment = false;
        }
        validPayment = !validPayment || this.disablePayLater || !this.toc_acceptance || !this.toc_acceptance_direct_debit || !this.showBsbValid;
      } else if (this.tabIndex == "EFT") {
        validPayment = !this.toc_acceptance;
      }

      return validPayment;
    },

    handlePayNowDisable() {
      let validPayment = true;
      if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has('cardHolder')) {
        validPayment = false;
      }

      return !validPayment || this.disablePayNow || !this.payNowAcceptance || this.showExpDateIsRequired || this.showExpired;
    },

    initialPayment() {
      let initialAmount = 0;

      if (this.prTransactionSchedule && this.prTransactionSchedule.length) {
        initialAmount = this.prTransactionSchedule[0].amount;
      }

      return initialAmount;
    },

    idvDetails() {
      let idvText = "";
      let idvNumber = "";

      if (this.formData.id_type == "DL") {
        idvText = "Driver license number";
        idvNumber = this.formData.licenseDetails.license_number;
      } else if (this.formData.id_type == "PP") {
        idvText = "Passport number";
        idvNumber = this.formData.passportDetails.passport_number;
      }

      return idvText + " " + idvNumber;
    }
  },
  watch: {
    "formData.sendConfirmText"(val) {
      if (this.emailPattern(val) === false) {
        this.sendConfirmTextError = "Invalid email address or mobile number";
        this.disableSendConfirmButton = true;
      } else {
        this.disableSendConfirmButton = false;
        this.sendConfirmTextError = "";
      }
    },

    formData: {
      handler(val) {
        this.disableIdentitySubmit = false;
      },
      deep: true
    },

    "errors.items"(val) {
      if (this.errors.items.length > 0) {
        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }
      } else {
        if (this.disablePayLater && !this.disableButtonAfterSubmit) {
          this.disablePayLater = false;
        }
      }
    },

    selectedPaymentType() {
      if (this.formData.payment_type && this.formData.payment_type !== "pay-now") {
        if (!this.disableDeviceVerification) {
          this.showAcceptAgreement = false;
          this.showPayNow = false;
          this.showPaymentOptions = false;
        }

        this.formData.selectedPaymentPlan = this.quote_data.find(
          (item) => (item.currentPlan.customPlanId === this.formData.payment_type || item.currentPlan._id === this.formData.payment_type)
        );

        const currentPlan = this.formData.selectedPaymentPlan && this.formData.selectedPaymentPlan.currentPlan;

        if (currentPlan) {
          this.paymentMethodDescription = {
            paymentSectionHeading: currentPlan.paymentSectionHeading ? currentPlan.paymentSectionHeading : "Payment method",
            paymentMethodDescription: currentPlan.paymentMethodDescription ? currentPlan.paymentMethodDescription : "",
          }
        }

        if (!this.isCustomerLoggedIn) {
          this.showIdMatrixVerification = true;
        } else {
          this.showMobileVerification = false;
          this.showPaymentRadioButton = true;
        }

        this.showPayNow = false;

        if (this.idMatrix.verified) {
          this.showMobileVerification = true;
        }

        if (this.isEmailVerified) {
          this.formData.commsPreference.email = true;
          this.showPaymentOptions = true;
          this.showPaymentPlan = true;
          this.showAcceptAgreement = true;
        }
      } else if (this.formData.payment_type === "pay-now") {
        this.showPaymentOptions = true;
        this.showPayNow = true;
        this.showPaymentRadioButton = false;
        this.showPaymentPlan = false;
        this.showAcceptAgreement = false;
        this.showMobileVerification = false;
      }
    },

    "formData.bsb"(val) {
      if (val.length == 0) {
        this.showBsbValid = true;
      }
    },

    step(val) {
      for (let i = 0; i < (val - 1); i++) {
        this.addVisited(i);
      }
      let offset = 45;

      if (!this.initialStep && (this.selectedPaymentType != "pay-now")) {
        offset = 90;
      }

      const visitedIndex = this.visitedProducts.findIndex(item => item.product == this.previousSelectedOption);

      if (visitedIndex >= 0 && this.visitedProducts[visitedIndex] && (this.visitedProducts[visitedIndex].step < val)) {
        this.visitedProducts[visitedIndex].step = val;
      }

      const el = document.getElementById("stepper-div");
      this.$scrollTo(el, 500, { offset: offset });
      this.initialStep = true;
    },

  },
  methods: {
    ...mapActions("payment", [
      "getPaymentDetails",
      "getProductCards",
      "setSelectedRequestType",
      "verifyIDMatrix",
    ]),
    ...mapActions("paymentRequest", ["getPlanByCustomPlanId"]),
    ...mapActions("apiLog", ["storeApiLogRequest", "storeApiLogResponse"]),
    ...mapActions("legals", ["fetchTermsAndConditions"]),

    maskBsb(string) {
      if (!string.includes("-")) {
        let arr = string.replace(" ", "").split("");
        arr.splice(3, 0, "-");
        string = arr.join("");
      }

      return string;
    },

    getIdentityCardStep() {
      let identityStep = 0;

      if ((!this.hasRequiredInformation && !this.checkDisbursementExist()) || (!this.hasRequiredInformation && this.checkDisbursementExist())) {
        identityStep = 2;
      } else if ((this.hasRequiredInformation && !this.checkDisbursementExist()) || (this.hasRequiredInformation && this.checkDisbursementExist())) {
        identityStep = 3;
      }

      return identityStep;
    },

    handleDisbursement(val) {
      if (val.resetToDefault) {
        this.formData.disbursement = { ...this.defaultDisbursement };
        this.showPaymentOptions = false;
      }

      if (val.submitDisbursement) {
        this.showPaymentOptions = true;
        this.paymentRequestDetail.isDisbursementComplete = true;
        this.disbursementStepCompleted = true;
        this.disbursementDetailToggle = false;
        this.step = this.hasRequiredInformation ? 5 : 4;
      }
    },

    formatAddress(val) {
      if (!val) {
        return val;
      }

      let obj = JSON.parse(val);
      let address = "";

      if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
        address = obj.unit_number + "<br>";
      }

      if (obj.street_name && Object.keys(obj.street_name).length > 0) {
        address = address + obj.street_name + "<br>";
      } else if (obj.street && Object.keys(obj.street).length > 0) {
        address = address + obj.street + "<br>";
      }

      if (obj.suburb && Object.keys(obj.suburb).length > 0) {
        address = address + obj.suburb + " ";
      }

      if (obj.state && Object.keys(obj.state).length > 0) {
        address = address + obj.state;
        if (Object.keys(obj.postcode).length > 0) {
          address = address + " " + obj.postcode + "<br>";
        } else {
          address = address + "<br>";
        }
      }

      if (obj.country && Object.keys(obj.country).length > 0) {
        address = address + obj.country;
      } else {
        address = address + "Australia";
      }

      return address;
    },

    async getCardsForPayLater() {
      await this.getProductCards().then((res) => {
        this.cardsForPaylater = res.data.data;
        this.cardsForPaylater.map((item) => {
          item.card = {};
          item.card.icon = item.icon;
          item.card.slug = item.slug;
        });
      }).catch(() => {});
    },

    emailPattern(val) {
      const regx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^04\(?\d{8}\)?$/;
      return regx.test(val);
    },

    // clearCard() {
    //   this.formData.name = "";
    //   this.formData.exp_date = "";
    //   this.formData.card_expiry_date = "";
    //   this.formData.exp_month = "";
    //   this.formData.exp_year = "";
    //   this.toc_acceptance = false;
    //   this.toc_acceptance_direct_debit = false;
    //   this.payNowAcceptance = false;
    //   this.showCardNumberIsRequired = false;
    //   this.isCardAllowed = true;
    //   this.cardValid = true;
    //   this.showCvcIsRequired = false;
    //   this.isInvalidCvv = false;
    //   this.showExpired = false;
    //   this.showExpDateIsRequired = false;
    //   this.dateSuccess = false;
    //   this.dateError = true;
    //   this.showBsbValid = true;
    //   this.bsbSuccess = false;
    //   this.bsbError = true;
    //   this.cardValidity = true;
    //   this.cardSuccess = false;
    //   this.cvvSuccess = false;
    //   this.cardNameValid = false;
    //   this.accountNameValid = false;
    //   this.accountNumberValid = false;
    //   this.$validator.reset();
    //   this.errors.clear();
    //   this.selectTab(this.paymentMethods[0]);
    // },

    clearFieldsForPaymentType(type) {
      // this.clearCard();

      if (type === "pay-now") {
        // clear mobile if id matrix verified but device is not and user switches to pay-now
        if (this.idMatrix.verified && !this.isDeviceVerified) {
          this.formData.maskedMobileNumber = "";
        }

        return;
      } else {
        this.formData.unit_number = "";
        this.formData.street_number = "";
        this.formData.street_name = "";
        this.formData.suburb = "";
        this.formData.state = "";
        this.formData.postcode = "";
        this.formData.first_name = "";
        this.formData.middle_name = "";
        this.formData.last_name = "";
        this.formData.date_of_birth = "";
        this.formData.licenseDetails.license_number = "";
        this.formData.licenseDetails.state = "";
        this.formData.licenseDetails.expiry_date = "";
        this.formData.passportDetails.passport_number = "";
        this.formData.passportDetails.issue_date = "";
        this.formData.passportDetails.expiry_date = "";

        return;
      }
    },

    async savePaymentType(event) {
      this.changePayNowExplicitTerms(event);

      if (event == "pay-now" || event == "now") {
        this.paymentTypeForSummary = "pay-now";
        this.paymentMethods = [];

        if (this.paymentDetails.enableCardPayment) {
          if (this.defaultCustomFields.length > 0) {
            this.inputArrayFiltered = this.inputArray.filter((item) => {
              return item.fieldType == "custom";
            });
          }

          await this.fetchFieldValue();
          this.card_options = this.paymentResponseData.platformCards || this.paymentResponseData.paymentCards;
          this.till_public_key = this.paymentResponseData.paymentDetails.accountId.publicIntegration;

          if (this.payNowConfirmationError == true) {
            this.showConfimationForError = true;
            this.showConfirmationForPayLaterError = false;

            if (this.till_failure_id_now) {
              this.till_failure_id = this.till_failure_id_now;
            } else {
              this.till_failure_id = "";
            }
          } else {
            this.till_failure_id = "";
            this.showConfimationForError = false;
            this.showConfirmationForPayLaterError = false;
          }

          if (this.$refs.customCollapse) {
            this.$refs.customCollapse.maxHeight = "none !important";
          }

          this.paymentMethods.push("CARD");
        }

        if (this.paymentDetails.eftDetails && Object.keys(this.paymentDetails.eftDetails).length) {
          this.eftEnabled = true;
          this.paymentMethods.push("EFT");
        }

        this.showExplainer = false;
        this.purchaseAmount = this.formData.amount.$numberDecimal || this.formData.amount;
      } else {
        this.eftEnabled = false;
        this.getSelelectedFields(event);
        this.getProductSummary(event);
        this.inputArrayFiltered = this.inputArray;

        this.steps = !this.inputArrayFiltered.length ? 3 : 4; // TODO: NEED TO UPDATE STEPS AS PER PRODUCT CONFIGURATION
        this.steps = this.checkDisbursementExist() ? this.steps + 1 : this.steps;

        this.card_options = this.cardsForPaylater;

        await this.fetchFieldValue();

        if (this.activePlan && this.activePlan.flipConnector) {
          this.till_public_key = this.activePlan.flipConnector.publicIntegration;
        } else {
          this.till_public_key = "";
        }

        if (this.payLaterConfirmationError == true) {
          this.showConfimationForError = false;
          this.showConfirmationForPayLaterError = true;
          if (this.till_failure_id_later) {
            this.till_failure_id = this.till_failure_id_later;
          } else {
            this.till_failure_id = "";
          }
        } else {
          this.till_failure_id = "";
          this.showConfimationForError = false;
          this.showConfirmationForPayLaterError = false;
        }

        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }

        const currentPlan = this.formData.selectedPaymentPlan && this.formData.selectedPaymentPlan.currentPlan ? this.formData.selectedPaymentPlan.currentPlan : null;
        this.paymentMethods = currentPlan && currentPlan.paymentMethods && currentPlan.paymentMethods.paymentPage && currentPlan.paymentMethods.paymentPage.length > 0 ? currentPlan.paymentMethods.paymentPage : ["EFT"];
        this.paymentMethods.sort();
      }

      this.enablePaymentMethods = true;
      // TODO CHANGE STEP AS PER PAY NOW / PAY LATER AND COMPLETED STEP
      if (event === "pay-now") {
        const requiredInfoCompleted = (this.paymentRequestDetail.customFields && this.paymentRequestDetail.customFields.length && this.paymentRequestDetail.isRequiredInformationCompletedWithPayNow) ;
        this.step = requiredInfoCompleted ? 2 : 1;
        this.requiredInformationStepCompleted = requiredInfoCompleted;
        this.yourIdentityStepCompleted = false;
        this.contactDetailStepCompleted = false;
        this.disbursementStepCompleted = false;
      } else {
        const productHasFields = (this.inputArrayFiltered && this.inputArrayFiltered.length > 0);
        const visitedProduct = this.visitedProducts.find(item => item.product == event);

        let isProductFieldCompleted = true;

        if (productHasFields) {
          isProductFieldCompleted = (this.paymentRequestDetail.isRequiredInformationCompletedWithProduct || this.requiredInformationStepCompleted) && (this.paymentRequestDetail.selectedRequestType == event);

          if (visitedProduct) {
            isProductFieldCompleted = (visitedProduct && (visitedProduct.step > 2) && (this.paymentRequestDetail.isRequiredInformationCompletedWithProduct || this.requiredInformationStepCompleted));
          }
        }

        if (this.checkDisbursementExist() && this.paymentRequestDetail.isDisbursementComplete && this.idMatrix.verified && (this.paymentRequestDetail.selectedRequestType == event || isProductFieldCompleted)) {
          this.requiredInformationStepCompleted = productHasFields;
          this.yourIdentityStepCompleted = true;
          this.contactDetailStepCompleted = true;
          this.disbursementStepCompleted = true;
          this.step = productHasFields ? 5 : 4;
        } else if (this.idMatrix.verified && (this.paymentRequestDetail.selectedRequestType == event || isProductFieldCompleted)) {
          this.requiredInformationStepCompleted = productHasFields;
          this.yourIdentityStepCompleted = true;
          this.contactDetailStepCompleted = true;
          this.step = productHasFields ? 4 : 3;
        } else if (isProductFieldCompleted && (this.paymentRequestDetail.deviceVerificationStatus === "Completed")) {
          this.requiredInformationStepCompleted = productHasFields;
          this.yourIdentityStepCompleted = this.idMatrix.verified;
          this.contactDetailStepCompleted = true;
          this.step = productHasFields ? 3 : 2;
        } else if (this.paymentRequestDetail.deviceVerificationStatus === "Completed") {
          this.requiredInformationStepCompleted = false;
          this.yourIdentityStepCompleted = this.idMatrix.verified;
          this.contactDetailStepCompleted = true;
          this.step = 2;
        } else {
          this.step = 1;
          this.requiredInformationStepCompleted = false;
          this.yourIdentityStepCompleted = false;
          this.contactDetailStepCompleted = false;
        }

        this.showExplainer = (this.step == 1) && this.selectedPlanForDescription && this.selectedPlanForDescription.explainer && this.selectedPlanForDescription.explainer.isEnabled ? true : false;
      }

      const visitedIndex = this.visitedProducts.findIndex(item => item.product == event);

      if (visitedIndex < 0) {
        this.visitedProducts.push({
          product: event,
          step: this.step
        });
      }

      this.previousSelectedOption = event;
      this.clearFieldsForPaymentType(event);
    },

    changePayNowExplicitTerms(event) {
      if (event == "pay-now" || event == "now") {
        this.merchantDescriptor = this.paymentDetails.partnerId.merchantDescriptor;
      } else {
        this.merchantDescriptor = process.env.VUE_APP_NAME.toLowerCase().includes("flip") ? "FlipPay.com.au" : "rello.com.au";
      }

      if (this.payNowLegals && this.payNowLegals.explicitTerms) {
        this.payNowLegals.explicitTerms = this.replaceLegalContent(
          this.payNowLegalsDefault.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payNowLegals._id}?type=${this.payNowLegals.nature}&fullDocument=true&mode=${event}" target="_blank">${this.payNowLegals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
            merchantDescriptor: this.merchantDescriptor
          }
        );
      }
    },

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    pfFormatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    formatNumber(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {minimumFractionDigits: 0});
    },

    navigateToNewTab(routeParams) {
      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },

    prepareSchedule() {
      let rule = { dtstart: new Date(this.paymentRequestFrequency.startDate) };

      if (this.paymentRequestFrequency.every === "week") {
        rule.freq = RRule.WEEKLY;
        rule.interval = this.paymentRequestFrequency.interval ? parseInt(this.paymentRequestFrequency.interval) : 0;
      } else {
        rule.freq = RRule.MONTHLY;
        rule.interval = this.paymentRequestFrequency.interval ? parseInt(this.paymentRequestFrequency.interval) : 0;
      }

      if (this.paymentRequestFrequency.endType === "by") {
        rule.until = this.paymentRequestFrequency.endDate ? new Date(this.paymentRequestFrequency.endDate) : new Date();
      } else {
        rule.count = this.paymentRequestFrequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestFrequency.endAfterNumberOfPayments) : 1;
      }

      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentList(rule.all());
    },

    paymentList(schedule) {
      let list = [];

      if (schedule.length > 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];
        list.push({
          label: "Second payment",
          value: schedule[1],
        });
        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];

        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 1) {
        list.push({
          label: "Payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];
      }

      this.totalNumberOfPayments = schedule.length;
      this.paymentSchedules = list;
      let endStatement = "";

      if (this.paymentRequestFrequency.endType === "by") {
        endStatement = "\n and ending by " + this.momentDate(this.paymentRequestFrequency.endDate);
      } else {
        endStatement = " and ending after " + this.paymentRequestFrequency.endAfterNumberOfPayments + " payments \nLast payment on " + this.momentDate(schedule[schedule.length - 1]);
      }

      this.scheduleDescription = "Every " + this.paymentRequestFrequency.interval + " " + this.paymentRequestFrequency.every + "s, " + "starting on " + this.momentDate(this.firstPayment) + "\n </br> </br>" + endStatement;

      this.scheduleDescriptionArray.push(
        this.paymentRequestFrequency.interval == 1
          ? `${this.paymentRequestFrequency.every.charAt(0).toUpperCase() + this.paymentRequestFrequency.every.slice(1)}ly, starting on ` + this.momentDate(this.firstPayment)
          : "Every " + this.paymentRequestFrequency.interval + " " + this.paymentRequestFrequency.every + "s, starting on " + this.momentDate(this.firstPayment)
      );

      if (this.paymentRequestFrequency.endType === "by") {
        this.scheduleDescriptionArray.push("Ending by " + this.momentDate(this.paymentRequestFrequency.endDate));
      } else {
        this.scheduleDescriptionArray.push("Ending after " + this.paymentRequestFrequency.endAfterNumberOfPayments + " payments");
        this.scheduleDescriptionArray.push("Last payment on " + this.momentDate(schedule[schedule.length - 1]));
      }
    },

    momentDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    checkExpiryDate(event) {
      const expiryDate = this.formData.card_expiry_date.split("/");
      const firstValue = expiryDate[0].charAt(0);

      if (firstValue >= 2) {
        this.formData.card_expiry_date = "0" + firstValue;
      }
      if (event.key == '/' && this.formData.card_expiry_date.length == 1) {
        this.formData.card_expiry_date = "0" + firstValue + "/";
      }

      if (expiryDate.length == 2) {
        const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;
        this.formData.exp_month = expiryDate[0];
        this.formData.exp_year = expiryDate[1];

        if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
          return false;
        }
        return !moment(fullDate).isAfter(new Date());
      }

      return false;
    },

    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },

    verifyId() {
      let idMatrixVerificationObject = {
        id: this.paymentRequestId,
        enquiryId: this.idMatrix.enquiryId,
        familyName: this.formData.last_name,
        firstGivenName: (this.formData && this.formData.first_name) ? this.formData.first_name : "",
        otherGivenName: this.formData.middle_name,
        dateOfBirth: this.formData.date_of_birth ? moment(this.formData.date_of_birth).format("YYYY-MM-DD") : "",
        gender: this.formData.gender,
        property: this.formData.property,
        unitNumber: this.trimData(this.formData.unit_number),
        streetNumber: this.trimData(this.formData.street_number),
        streetName: this.trimData(this.formData.street_name),
        streetType: this.trimData(this.formData.street_type),
        suburb: this.trimData(this.formData.suburb),
        state: this.trimData(this.formData.state),
        postCode: this.trimData(this.formData.postcode),
        country: "AUS",
        consent: this.authorized_to_use,
        merchantId: this.merchantId,
        streetNameOnly: this.trimData(this.streetNameOnly),
        rawAddress: this.trimData(this.formData.rawAddress),
      };

      if (!this.formData.rawAddress || (this.formData.rawAddress == "")) {
        if (!this.formData.postcode || (this.formData.postcode == "")) {
          this.showErrorMessage("ID Verification", "address is required");
          this.idMatrixValidations.push({ rawAddress: "address is required" });
        } else {
          this.showErrorMessage("ID Verification", "Please select address from address suggestion list");
        }
        return;
      }

      if (this.formData.id_type === "DL") {
        idMatrixVerificationObject["license"] = {
          stateCode: this.formData.licenseDetails.state,
          number: this.formData.licenseDetails.license_number,
          cardNumber: this.formData.licenseDetails.card_number,
          expiryDate: this.formData.licenseDetails.expiry_date,
        };
      } else {
        idMatrixVerificationObject["passport"] = {
          countryCode: "AUS",
          number: this.formData.passportDetails.passport_number,
          issueDate: this.formData.passportDetails.issue_date,
          expiryDate: this.formData.passportDetails.expiry_date,
        };
      }

      this.$vs.loading();
      this.verifyIDMatrix(idMatrixVerificationObject).then((res) => {
        this.$vs.loading.close();
        if (res.data.data.overallOutcome === "ACCEPT") {
          this.idMatrix.verified = true;
          this.showMobileVerification = true;

          if (this.showConfimationForError == true) {
            this.showConfimationForError = false;
          }
          this.idvData = {
            name: this.formData.first_name + " " + this.formData.middle_name + " " + this.formData.last_name,
            residentialAddress : this.formData.rawAddress
          };

          if (this.formData.id_type == "DL") {
            this.idvData.drivingLicence = {
              licenceNumber : this.formData.licenseDetails.license_number
            };
          } else if (this.formData.id_type == "PP") {
            this.idvData.passportNumber = this.formData.passportDetails.passport_number;
          }

          this.step += 1;
          this.yourIdentityStepCompleted = true;
        } else if (res.data.data.overallOutcome === "REJECT") {
          this.idMatrix.enquiryId = res.data.data.enquiryId;
          this.idMatrix.verified = false;
          this.hideIdErrorBlock = false;
          this.idMatrix.verifyCount += 1;
          this.disableIdentitySubmit = true;

          if (res.data.data.payLaterRemoved) {
            this.showSelectedOption = false;
            this.products = [];
            this.eftEnabled = false;
            if (this.paymentRequestDetail.requestOptions.includes("pay-now")) {
              this.showPayNow = true;
            } else if (res.data.data.payNowAdded) {
              this.paymentRequestDetail.requestOptions.push("pay-now");
              this.showPayNow = true;
            } else {
              this.processPayment = false;
            }
          }
        } else {
          throw res;
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        let errorMessage = ex.data.message;

        if (ex.status == 422) {
          this.idMatrixValidations = ex.data.details;
        } else if (ex.status === 500) {
          this.showErrorMessage("ID Verification", ex.data.message || "We are unable to process your request at the moment. Please try again in a few minutes.");
        }

        this.hideIdErrorBlock = false;
      });
    },

    getAddressData: function (addressData, placeResultData, _id) {
      this.addressData = addressData;
      this.formData.rawAddress = placeResultData.formatted_address;

      if (this.addressData.street_number) {
        this.formData.street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.formData.postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.formData.suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.formData.state = this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";
        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
            this.streetNameOnly = address.long_name;
          }
        }

        this.formData.street_name = street_address;
      }
    },

    getRawAddress(val) {
      let address = "";
      let addressData = {};

      if (val == "formdata") {
        addressData = this.formData;
      } else {
        addressData = this.productAddressFieldArray[val];
      }

      if (addressData.unit_number && Object.keys(addressData.unit_number).length > 0) {
        address = this.trimData(addressData.unit_number) + " ";
      }

      if (addressData.street_name && Object.keys(addressData.street_name).length > 0) {
        address = address + this.trimData(addressData.street_name) + ", ";
      } else if (addressData.street && Object.keys(addressData.street).length > 0) {
        address = address + this.trimData(addressData.street) + ", ";
      }

      if (addressData.suburb && Object.keys(addressData.suburb).length > 0) {
        address = address + this.trimData(addressData.suburb) + " ";
      }

      if (addressData.state && Object.keys(addressData.state).length > 0) {
        address = address + this.trimData(addressData.state);
        if (Object.keys(addressData.postcode).length > 0) {
          address = address + " " + this.trimData(addressData.postcode) + ", ";
        } else {
          address = address + ", ";
        }
      }

      if (addressData.country && Object.keys(addressData.country).length > 0) {
        address = address + this.trimData(addressData.country);
      } else if (Object.keys(address).length) {
        address = address + "Australia";
      }

      if (val == "formdata") {
        this.formData.rawAddress = this.trimData(address);
      } else {
        this.productAddressFieldArray[val].rawAddress = this.trimData(address);
      }

      return address;
    },

    clearAddress() {
      this.formData.rawAddress = "";
      this.formData.unit_number = "";
      this.formData.street_number = "";
      this.formData.street_name = "";
      this.formData.suburb = "";
      this.formData.state = "";
      this.formData.postcode = "";
      this.formData.country = "";
    },

    clearProductFieldAddress(id) {
      this.productAddressFieldArray[id] = {
        rawAddress: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        suburb: "",
        state: "",
        postcode: "",
        country: "",
      };
    },

    cardNameChange(event) {
      const value = event.target.value;
      this.showNameIsRequired = value.length === 0;
    },

    async fetchCustomerLegals() {
      await this.fetchTermsAndConditions().then((result) => {
        this.customerLegals = result.data.data;
        this.privacyLegals = this.customerLegals.privacy ? this.customerLegals.privacy : {};
        this.termsAndCondition = this.customerLegals.websiteTerms ? this.customerLegals.websiteTerms : {};
        this.payNowLegalsDefault = this.customerLegals.tnc ? { ...this.customerLegals.tnc } : {};
        this.payNowLegals = this.customerLegals.tnc ? this.customerLegals.tnc : {};
        this.directDebitLegals = this.customerLegals.directDebitTerms ? this.customerLegals.directDebitTerms : {};

        if (this.payNowLegals && this.payNowLegals.explicitTerms) {
          this.payNowLegals.explicitTerms = this.replaceLegalContent(
            this.payNowLegals.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payNowLegals._id}?type=${this.payNowLegals.nature}&fullDocument=true&mode=pay-now" target="_blank">${this.payNowLegals.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
            }
          );
        }

        if (this.directDebitLegals && this.directDebitLegals.explicitTerms) {
          this.directDebitLegals.explicitTerms = this.replaceLegalContent(
            this.directDebitLegals.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.directDebitLegals._id}?type=${this.directDebitLegals.nature}&fullDocument=true" target="_blank">${this.directDebitLegals.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
            }
          );
        }
      }).catch((ex) => {
        this.showErrorMessage('Error', ex.message);
      });
    },

    async fetchPlanByCustomFieldId(planId) {
      this.activePlan = this.paymentPlans.find((plan) => plan.customPlanId == planId);
      let terms = this.activePlan.legals.filter((legal) => legal.user === "CUSTOMER");
      terms = _.sortBy(terms, ["createdAt"]).reverse();
      this.payLaterLegals = terms[0];

      if (this.payLaterLegals.explicitTerms) {
        this.payLaterLegals.explicitTerms = this.replaceLegalContent(
          this.payLaterLegals.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payLaterLegals._id}?type=PAY_LATER_LEGAL&fullDocument=true" target="_blank">${this.payLaterLegals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
          }
        );
      }
    },

    showPreview(key) {
      return (
        (
          this.inputArrayFiltered[key].hasOwnProperty('value') &&
          typeof this.inputArrayFiltered[key].value === 'string' &&
          this.inputArrayFiltered[key].value.length > 0
        )
        ||
        (
          this.inputArrayFiltered[key].hasOwnProperty('selectedFile') &&
          typeof this.inputArrayFiltered[key].selectedFile === 'object'
        )
      )
    },

    showErrorMessage(title = 'Error', message='Something went wrong.', time = false){
      this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-danger",
          iconChildren: "highlight_off",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    idVerifyAuthorized(val) {
      this.authorized_to_use = val;
    },

    getSelelectedFields(event) {
      try {
        this.productCustomFields = [];
        this.inputArrayFiltered = [];
        this.inputArray = [];
        this.customFields = [];
        this.defaultCustomFields = [];

        const paymentDetails = this.paymentDetails;

        if (this.paymentDetails.customFields && paymentDetails.customFields.length > 0 && paymentDetails.customFields[0].label) {
          this.customFields = JSON.parse(JSON.stringify(paymentDetails.customFields));
          this.customFields.map((item) => {
            item.fieldType = "custom";
            return item;
          });
          this.defaultCustomFields = [...this.customFields];
          this.customFields = _.filter(this.customFields, function (o) {
            return ((o.displayToCustomer && o.value) || o.editableByCustomer || o.requiredToSubmit);
          });
        }

        if (this.customFields.length > 0) {
          this.payNowAdditional = true;
        }

        if ((paymentDetails.productCustomFields && paymentDetails.productCustomFields.length > 0) || (paymentDetails.payLaterPlan && paymentDetails.payLaterPlan.length > 0)) {
          let fields = [];

          if (event !== "pay-now") {
            if (this.paymentDetails.apiVersion && this.paymentDetails.apiVersion == 2) {
              const planData = this.paymentDetails.payLaterPlan.find((element) => {
                return element._id == event;
              });
              this.productType = planData.currentPlan.productType;

              fields = planData.customFields;
            } else {
              fields = paymentDetails.productCustomFields;
            }
          }

          if (fields && fields.length > 0) {
            this.productCustomFields = [ ...JSON.parse(JSON.stringify(fields)) ];
          }

          this.payNowAdditional = (this.customFields.length > 0);
          this.productCustomFields.map((item) => {
            item.fieldType = "product";
            return item;
          });

          const defaultProductAddressField = {...this.productAddressField };

          this.productCustomFields.forEach((e, index) => {
            if (e.type == "address") {
              if (e.value) {
                this.productAddressField = JSON.parse(e.value);

                if (!this.productAddressField.street_name) {
                  this.productAddressField.street_name = this.productAddressField.street ? this.productAddressField.street : '';
                }

                this.productAddressFieldArray[e._id] = this.productAddressField;
                this.productAddressFieldArray[e._id].rawAddress = this.getRawAddress(e._id);
              } else {
                if (!this.productAddressFieldArray || !this.productAddressFieldArray[e._id]) {
                  this.productAddressFieldArray[e._id] = defaultProductAddressField;
                }
              }
            }
          });

          this.defaultCustomFields = [...this.defaultCustomFields, ...this.productCustomFields];
          this.productCustomFieldsNotViewable = _.filter(
            this.productCustomFields,
            function (o) {
              return (!(o.displayToCustomer && o.value) && !o.editableByCustomer && !o.requiredToSubmit);
            }
          );
          this.productCustomFields = _.filter(
            this.productCustomFields,
            function (o) {
              return ((o.displayToCustomer && o.value) || o.editableByCustomer || o.requiredToSubmit);
            }
          );
          this.customFields = [ ...this.productCustomFields, ...this.customFields ];
        }

        this.customFields.forEach((e, index) => {
          if (e.requiredToSubmit) {
            this.additionalOpen = true;
          }

          e.value = e.value ? e.value : "";
          this.inputArray.push(e);
        });

        this.inputArray.forEach((item) => {
          if (item.type == "currency" && (item.editableByCustomer || item.requiredToSubmit)) {
            item.value = item.value;
            item.value = this.addZeroes(item.value);
          }
        });
      } catch (ex) {
        sentryLog(ex, "Error getting product fields");
      }
    },

    getProductSummary(planId) {
      this.paymentTypeForSummary = "pay-later";

      if (this.planTransactions && this.planTransactions.length) {
        const selectedTransaction = this.planTransactions.find((element) => {
          return element.planObjectId == planId;
        });

        if (selectedTransaction) {
          this.prTransactionSchedule = selectedTransaction.tansactionSchedule;
          const total = this.prTransactionSchedule.find((item) => item.type == "Total");
          const disbursementAmount = this.prTransactionSchedule.find((item) => item.type == "Disbursement");
          const transactionFee = this.prTransactionSchedule.find((item) => item.type == "Fee");
          this.feeAmount = transactionFee.amount;
          this.purchaseAmount = disbursementAmount.amount;
          this.total = total.amount;
          this.selectedPlanId = selectedTransaction.planId;
          this.fetchPlanByCustomFieldId(this.selectedPlanId);
        }
      }
    },

    async fetchFieldValue() {
      this.inputArrayFiltered = this.inputArrayFiltered.map((item) => {
        if (item.type == "file") {
          if ((item.editableByCustomer || item.requiredToSubmit) && !item.value && this.tempArray && this.tempArray[item._id]) {
            item.selectedFile = this.tempArray[item._id];
          }
        } else {
          item.value = this.tempArray[item._id] ? this.tempArray[item._id] : item.value;
        }

        if (item.type == "currency" && (item.editableByCustomer || item.requiredToSubmit)) {
          item.value = (typeof item.value == "string") ? item.value : this.addZeroes(item.value);
        }

        item.isExpanded = false;

        return item;
      });
    },

    checkDisbursementExist() {
      return this.paymentRequestDetail.disbursement && (this.paymentRequestDetail.disbursement.disburseTo == "external") && this.paymentRequestDetail.disbursement.customerCanEditDisbursement;
    },

    // NEW METHODS
    async selectProduct(value) {
      const payload = {
        paymentRequestId: this.paymentRequestDetail._id,
        data: { selectedRequestType: value },
      };

      // Stores selected product id or pay-now
      await this.setSelectedRequestType(payload);

      if (value == "pay-now") {
        this.selectedRequestOption = "pay-now";
        this.paymentTypeForSummary = "pay-now";
        this.selectedPlan = "";
      } else {
        this.selectedRequestOption = "pay-later";
        this.paymentTypeForSummary = "pay-later";

        const plan = this.paymentRequestDetail.payLaterPlan.find(item => item._id === value);
        this.selectedPlan = plan ? plan : "";
        const planForDescription = this.products.find(item => item._id === value);
        this.selectedPlanForDescription = planForDescription;
      }

      this.formData.payment_type = value;
      this.savePaymentType(value);
      this.showSelectedOption = true;
    },

    changeSelectedOption() {
      this.showSelectedOption = false;
      this.selectedRequestOption = "";
      this.selectedPlan = "";
      this.selectedPlanForDescription = "";
      this.showPayNow = this.displayPayNow;
      this.eftEnabled = this.paymentRequestDetail.eftDetails && Object.keys(this.paymentRequestDetail.eftDetails).length ? true : false;
      this.step = 1;
      this.enablePaymentMethods = false;
    },

    addVisited(index) {
      setTimeout(function() {
        document.getElementsByClassName("v-stepper-root")[0].children[index].classList.add("is-visited");
      }, 200);
    },

    mobileVerified(data) {
      this.contactDetailStepCompleted = true;
      this.step = 2;
      this.formData.mobile = data.mobile;
      this.formData.maskedMobileNumber = data.mobile;
      this.formData.email = data.email;
      this.paymentRequestDetail.deviceVerificationStatus = "Completed";
    },

    requiredInformationComplete() {
      this.requiredInformationStepCompleted = true;
      if (this.paymentTypeForSummary === "pay-now") {
        this.step = 2;
      } else {
        this.step = this.idMatrix.verified ? 4 : 3;
        this.yourIdentityStepCompleted = this.idMatrix.verified ? true : false;
      }
    },

    toggleExpand(index) {
      this.inputArrayFiltered[index].isExpanded = !this.inputArrayFiltered[index].isExpanded;
    },

    paymentSuccess(result) {
      this.paymentStatus = result.paymentStatus;

      if (result.paymentStatus === "Active") {
        this.successMessageHeading = this.selectedPlanForDescription && this.selectedPlanForDescription.paymentSuccessHeading ? this.selectedPlanForDescription.paymentSuccessHeading : "All done!";
        this.successMessageText = this.selectedPlanForDescription && this.selectedPlanForDescription.paymentSuccessText ? this.selectedPlanForDescription.paymentSuccessText : this.message;
      } else if (result.paymentStatus === "Submitted") {
        this.successMessageHeading = this.selectedPlanForDescription && this.selectedPlanForDescription.paymentSubmittedHeading ? this.selectedPlanForDescription.paymentSubmittedHeading : "Nearly there …";
        this.successMessageText = this.selectedPlanForDescription && this.selectedPlanForDescription.paymentSubmittedText ? this.selectedPlanForDescription.paymentSubmittedText : this.message;
      }

      this.showSuccessBox = true;
    },

    editDetail(type) {
      switch (type) {
        case "contactDetail":
          this.step = 1;
          this.contactDetailStepCompleted = false;
          this.contactDetailToggle = false;
          break;
        case "requiredInformation":
          this.step = (this.selectedPaymentType == "pay-now") ? 1 : 2;
          this.requiredInformationStepCompleted = false;
          this.requiredInformationToggle = false;
          break;
        case "disbursementDetail":
          this.step = this.hasRequiredInformation ? 4 : 3;
          this.disbursementStepCompleted = false;
          this.disbursementDetailToggle = false;
          break;
      }
    },

    maskNumber(string) {
      string = string.trim().split(" ").join("");
      return string.slice(0, string.length - 4).replace(/./g, "*")+ string.slice(string.length - 4);
    },

    trimData(data) {
      if (!data) {
        return;
      }

      return data.trim();
    }
  },

  created() {
    this.formData.sendConfirmText = this.formData.email;
      let paymentRequestId = this.$route.params.id || this.$route.params.paymentRequestId;
      this.$vs.loading();
      this.getPaymentDetails(paymentRequestId)
        .then(async (response) => {
          let responseData = response.data.data;
          this.paymentDetails = responseData.paymentDetails;

          if (this.paymentDetails && this.paymentDetails.payLaterPlan && this.paymentDetails.payLaterPlan.length && this.paymentDetails.payLaterPlan[0].currentPlan.productConfigType === "TWO_PARTY") {
            this.$vs.loading.close();
            return this.$router.push({ name: "payment-error" });
          }

          await this.fetchCustomerLegals();

          this.products = responseData.plans;
          this.transactions = responseData.transactions;
          this.card_options = responseData.platformCards && responseData.platformCards.length ? responseData.platformCards : responseData.paymentCards;
          this.payNowCards = this.paymentDetails.enableCardPayment ? this.card_options : [];
          this.paymentRequestId = responseData.paymentDetails._id;
          this.planTransactions = responseData.transactions;

          if (responseData.paymentDetails && responseData.paymentDetails.disbursement) {
            this.defaultDisbursement = { ...responseData.paymentDetails.disbursement, updatedByCustomer: false };

            if (this.defaultDisbursement && this.defaultDisbursement.accountBsb && !this.defaultDisbursement.accountBsb.includes("-")) {
              this.defaultDisbursement.accountBsb = this.maskBsb(this.defaultDisbursement.accountBsb);
            }

            this.formData.disbursement = { ...responseData.paymentDetails.disbursement, updatedByCustomer: false };
          }

          if (responseData.transactions && responseData.transactions.length) {
            this.prTransactionSchedule = responseData.transactions[0].tansactionSchedule;
            this.selectedPlanId = responseData.transactions[0].planId;
          }

          if (responseData.plans && responseData.plans.length) {
            this.paymentPlans = responseData.plans;
          }

          if (this.paymentDetails.requestOptions.includes("pay-later")) {
            const requestTemplate = responseData.paymentDetails && responseData.paymentDetails.paymentRequestTemplate ? responseData.paymentDetails.paymentRequestTemplate : null;
            const planId = requestTemplate && requestTemplate.paymentOptions[0] && requestTemplate.paymentOptions[0].payLaterPlan ? requestTemplate.paymentOptions[0].payLaterPlan[0] : "";

            if (planId) {
              this.planDisplayName = responseData.paymentDetails.partnerId.payLaterPlans.find((plan) => plan.customPlanId === planId).displayName;
            }
          }

          if (responseData.paymentDetails.paymentStatus === "Info-required") {
            this.$vs.loading.close();
            return this.$router.push({ name: "payment-forbidden" });
          }

          if (responseData.paymentDetails.requestOptions.includes("pay-later")) {
            await this.getCardsForPayLater();
          }

          if (responseData.paymentDetails.requestOptions[0] == "pay-later" && (responseData.paymentDetails.requestOptions.length === 1)) {
            this.card_options = this.cardsForPaylater;
          }

          this.paymentResponseData = responseData;
          this.paymentRequestDetail = responseData.paymentDetails;

          this.paymentRequestDetail.requestOptions = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "requestOptions", []);
          this.footerBind = true;
          this.redirectData = {
            success: {
              header: this.paymentRequestDetail.successHeading,
              body: this.paymentRequestDetail.successBody,
              url: this.paymentRequestDetail.successUrl,
              btnText: this.paymentRequestDetail.successButtonText,
            }
          };

          this.enableRedirectOnSuccess = this.paymentRequestDetail.paymentRequestTemplate ? this.paymentRequestDetail.paymentRequestTemplate.redirect.success.enableRedirect : false,

          this.$vs.loading.close();

          if (responseData.paymentDetails.requestType === "recurring") {
            this.isRecurring = true;
            this.page_type = "recurring";
            this.paymentRequestFrequency = responseData.paymentDetails.frequency;
            this.prepareSchedule();
          }

          this.paymentRID = responseData.paymentDetails.paymentRequestId;
          this.loginUrl = `${this.customerPortalUrl}${responseData.paymentDetails.partnerId.id}/customer-login`;
          this.merchantId = responseData.paymentDetails.partnerId.id;
          this.formData.paymentRequestId = responseData.paymentDetails._id;
          this.formData.amount = parseFloat(responseData.paymentDetails.amount.$numberDecimal);
          this.total = this.formData.amount;
          this.formData.reference = responseData.paymentDetails.reference;
          this.formData.senderDetails = responseData.paymentDetails.senderDetails;
          this.formData.mobile = responseData.paymentDetails.payeePhone;

          if (this.formData.mobile) {
            this.formData.maskedMobileNumber = this.formData.mobile;
          }

          this.formData.email = responseData.paymentDetails.payeeEmail;
          this.formData.sendConfirmText = responseData.paymentDetails.payeeEmail;

          if (responseData.paymentDetails.customFields && responseData.paymentDetails.customFields.length > 0 && responseData.paymentDetails.customFields[0].label) {
            this.customFields = JSON.parse(JSON.stringify(responseData.paymentDetails.customFields));
            this.customFields.map((item) => {
              item.fieldType = "custom";
              return item;
            });
            this.defaultCustomFields = [...this.customFields];
            this.customFields = _.filter(this.customFields, function (o) {
              return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
            });
          }

          this.payNowAdditional = (this.customFields.length > 0);

          if (responseData.paymentDetails.productCustomFields && responseData.paymentDetails.productCustomFields.length > 0 && responseData.paymentDetails.productCustomFields[0].label) {
            this.productCustomFields = [ ...JSON.parse(JSON.stringify(responseData.paymentDetails.productCustomFields)) ];
            this.productCustomFields.map((item) => {
              item.fieldType = "product";
              return item;
            });

            const defaultProductAddressField = { ...this.productAddressField };

            this.productCustomFields.forEach((e, index)=>{
              if (e.type == "address") {
                if (e.value) {
                  this.productAddressField = JSON.parse(e.value);

                  if (!this.productAddressField.street_name) {
                    this.productAddressField.street_name = this.productAddressField.street ? this.productAddressField.street : '';
                  }

                  this.productAddressFieldArray[e._id] = this.productAddressField;
                  this.productAddressFieldArray[e._id].rawAddress = this.getRawAddress(e._id);
                } else {
                  this.productAddressFieldArray[e._id] = defaultProductAddressField;
                }
              }
            });

            this.defaultCustomFields = [...this.defaultCustomFields, ...this.productCustomFields];

            this.productCustomFieldsNotViewable = _.filter(
              this.productCustomFields,
              function (o) {
                return (!o.displayToCustomer && !o.editableByCustomer && !o.requiredToSubmit);
              }
            );

            this.productCustomFields = _.filter(
              this.productCustomFields,
              function (o) {
                return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
              }
            );

            this.customFields = [ ...this.productCustomFields, ...this.customFields ];
          }

          this.customFields.forEach((e, index) => {
            if (e.requiredToSubmit) {
              this.additionalOpen = true;
            }

            e.value = e.value ? e.value : "";
            this.inputArray.push(e);
          });

          this.inputArray.forEach((item) => {
            if (item.type == "currency" && (item.editableByCustomer || item.requiredToSubmit)) {
              item.value = item.value.replace("$ ", "");
              item.value = this.addZeroes(item.value);
            }
          });

          this.formData.message = responseData.paymentDetails.message;
          this.formData.attachments = responseData.paymentDetails.files;

          if (this.paymentRequestDetail.createdBy) {
            this.formData.payment_request_from.name = responseData.paymentDetails.createdBy.fullName;
            this.formData.payment_request_from.email = responseData.paymentDetails.createdBy.email;
            this.formData.payment_request_from.phone_number = responseData.paymentDetails.createdBy.phoneNumber;
          }

          if (this.formData.payment_request_from.phone_number && this.formData.payment_request_from.phone_number !== "") {
            let phoneNumber = this.formData.payment_request_from.phone_number;

            if (phoneNumber.includes(" ")) {
              this.formData.payment_request_from.phone_number = phoneNumber;
            } else {
              let maskedNumber = phoneNumber.match(/(\d{4})(\d{3})(\d{3})/);
              this.formData.payment_request_from.phone_number = maskedNumber[1] + " " + maskedNumber[2] + " " + maskedNumber[3];
            }
          }

          let cardWithSurchargeStatus = this.card_options.find((card) => {
            if (parseFloat(card.surcharge) > 0) {
              return card;
            }
          });

          if (!cardWithSurchargeStatus) {
            this.cardWithSurcharge = false;
          }

          if (response.data.data.paymentDetails.requestType === "one-off") {
            let requestOptions = response.data.data.paymentDetails.requestOptions;

            if (requestOptions.length > 0) {
              let findPaylater = requestOptions.find((requestOption) => requestOption === "pay-later");
              this.page_type = findPaylater ? "paylater" : "normal";
            } else {
              this.page_type = "normal";
            }
          } else {
            this.page_type = "recurring";
          }

          if (this.page_type === "paylater") {
            const payLaterData = this.paymentRequestDetail.payLaterPlan;

            for (let i = 0; i< payLaterData.length; i++) {
              let quoteData = payLaterData[i];

              if (!this.paymentRequestDetail.requestOptions.includes("pay-now")) {
                this.formData.payment_type = quoteData.currentPlan.planId;
              }

              quoteData.plan_name = quoteData.currentPlan.productName;
              quoteData.payment_plan = quoteData.currentPlan;
              quoteData.productText = quoteData.currentPlan.productText;

              if (quoteData.currentPlan.productType === "B2C_BNPL") {
                if (quoteData.planText && quoteData.planText.length > 0) {
                  quoteData.planDescription = quoteData.planText[0] + '\r\n' + (quoteData.planText[1] ? quoteData.planText[1] : "");
                }
              }

              this.quote_data.push(quoteData);
              this.productType = quoteData.currentPlan.productType;
            }

            await this.fetchPlanByCustomFieldId(this.selectedPlanId);
          }

          let reqType = response.data.data.paymentDetails.requestType;
          let reqOptions = response.data.data.paymentDetails.requestOptions;

          if (this.paymentRequestDetail.selectedRequestType && this.paymentRequestDetail.selectedRequestType != "pay-now") {
            if (this.paymentRequestDetail.selectedRequestType === "pay-now" && this.paymentRequestDetail.enableCardPayment) {
              this.till_public_key = responseData.paymentDetails.accountId.publicIntegration;
            }

            if (this.paymentRequestDetail.selectedRequestType != "pay-now" && (this.paymentRequestDetail.deviceVerificationStatus === "Completed")) {
              this.selectProduct(this.paymentRequestDetail.selectedRequestType);
            }
          } else {
            if (reqType == "one-off" && reqOptions.length == 1 && reqOptions.includes("pay-later")) {
              this.savePaymentType(this.quote_data[0].currentPlan._id);
            } else {
              if (this.paymentRequestDetail.enableCardPayment) {
                this.till_public_key = responseData.paymentDetails.accountId.publicIntegration;
              }

              const onlyPayNow = (reqType == "one-off" && reqOptions.length == 1 && reqOptions.includes("pay-now"))

              if (onlyPayNow) {
                this.selectProduct("pay-now");
              }

              this.onlyPayNow = onlyPayNow;
            }
          }

          this.paymentBind = true;
          this.idMatrix.enquiryId = response.data.data.paymentDetails.idMatrixVerifications && response.data.data.paymentDetails.idMatrixVerifications[0] && response.data.data.paymentDetails.idMatrixVerifications[0].enquiryId;
          this.idMatrix.verifyCount = response.data.data.paymentDetails.idMatrixVerifications ? response.data.data.paymentDetails.idMatrixVerifications.length : 0;

          if (response.data.data.paymentDetails.idMatrixVerifications &&  response.data.data.paymentDetails.idMatrixVerifications. length > 0) {
            let lastIdMatrixData = response.data.data.paymentDetails.idMatrixVerifications[response.data.data.paymentDetails.idMatrixVerifications.length - 1];

            if (lastIdMatrixData) {
              this.hideIdErrorBlock = true;
              this.idMatrix.verified = lastIdMatrixData.overallOutcome === 'ACCEPT' ? true: false;
              this.yourIdentityStepCompleted = this.idMatrix.verified;
            }
          }

          this.idMatrixData = response.data.data.paymentDetails.idMatrixVerifications;
          this.idvData = response.data.data.paymentDetails.idvData || "";

          if (this.paymentRequestDetail.requestType !== "recurring" && this.paymentRequestDetail.requestOptions.length < 1) {
            this.hidePayNow = true;
          }

          if ((this.paymentRequestDetail.failurePayNowOption === false || this.paymentRequestDetail.failurePayNowOption === true) &&
            this.paymentRequestDetail.failureUrl &&
            this.paymentRequestDetail.failureDisplayText &&
            this.idMatrix.verifyCount > 3) {

            if (this.paymentRequestDetail.failurePayNowOption === true) {
              this.showDirectPayNowFromApiConfig = true;
            } else if (this.paymentRequestDetail.failurePayNowOption === false) {
              this.showDirectPayNowFromApiConfig = false;
            }
          }

          this.readyToRender = true;
        }).catch((ex) => {
          this.$vs.loading.close();
          sentryLog(ex);
          this.$router.push({ name: "payment-error" });
        });

      Validator.extend("customCurrency", {
        getMessage: (field) => `${field} field is required`,
        validate(value) {
          return new Promise((resolve) => {
            resolve({
              valid: value <= 0 ? false : !!value,
              data: value > 0 ? undefined : { message: "Not this value" },
            });
          });
        },
      });
      Validator.extend("cardNameFormat", {
        getMessage: () => "Name on card should contain at least one letter",
        validate: (value) => value.match(/[a-zA-Z]/g) !== null,
      });
      Validator.extend("alphanumFormat", {
        getMessage: () => "Invalid account number",
        validate: (value) => value.match(/^[a-zA-Z0-9]+$/) !== null,
      });
  },

  directives: {
    mask
  },
};
</script>

<style>
  #stepper-div {
    pointer-events: none;
  }
</style>